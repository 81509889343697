<template>
  <a-layout id="app-layout-sider">
    <a-layout-sider v-model="collapsed" theme="light" class="layout-sider">
      <div class="logo">
        <img class="pic-logo" :src="logo" />
      </div>
      <div class="menubox">
        <div class="item" :class="current == idx ? 'active' : ''" @click="menuHandle(i, idx)" v-for="(i, idx) in menu" :key="idx">
          <img :src="i.icon" alt="" />
          <div>{{ i.title }}</div>
        </div>
      </div>
    </a-layout-sider>
    <a-layout-content class="layout-content">
      <div class="nav">
        <div>欢迎使用易罗大陆AI数字人</div>
        <div class="df" style="-webkit-app-region: no-drag">
          <div>
            <el-dropdown trigger="click" @command="handleClick">
              <span class="el-dropdown-link">
                <!-- @click.stop="toUser" -->
                <div class="infoBox">
                  <el-avatar v-show="info.face" size="small" :src="info.face"></el-avatar>
                  <el-avatar v-show="!info.face" size="small" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
                  <span class="mlr5">{{ info.name }}</span>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </div>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="user" icon="el-icon-user">个人中心</el-dropdown-item>
                <el-dropdown-item command="edit" icon="el-icon-postcard">修改个人信息</el-dropdown-item>
                <el-dropdown-item command="logout" icon="el-icon-circle-close">退出登录</el-dropdown-item>
                <el-dropdown-item command="exit" icon="el-icon-switch-button">退出应用</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="contrl">
            <div @click="handleMinus">
              <i class="el-icon-minus"></i>
            </div>
            <div @click="handleClose">
              <i class="el-icon-close"></i>
            </div>
          </div>
        </div>
      </div>
      <router-view />
    </a-layout-content>
  </a-layout>
</template>
<script>
import { ipcApiRoute, specialIpcRoute } from "@/api/main";
export default {
  name: "AppSider",
  data() {
    return {
      collapsed: true,
      default_key: "menu_1",
      current: 0,
      info: {},
      logo: require("../assets/logo.png"),
      menu: [
        {
          icon: require("../assets/img/home.png"),
          title: "首页",
          pageName: "home",
          params: {},
        },
        {
          icon: require("../assets/img/dsp.png"),
          title: "AI短视频",
          pageName: "shortVideo",
          params: {},
        },
        {
          icon: require("../assets/img/live.png"),
          title: "AI直播",
          pageName: "live",
          params: {},
        },
        {
          icon: require("../assets/img/zz.png"),
          title: "AI创作",
          pageName: "text",
          params: {},
        },
        {
          icon: require("../assets/img/hh.png"),
          title: "AI绘画",
          pageName: "painting",
          params: {},
        },
        {
          icon: require("../assets/img/sz.png"),
          title: "设置",
          pageName: "setting",
          params: {},
        },
      ],
    };
  },
  watch: {
    $route(to, from) {
      this.info = JSON.parse(localStorage.getItem("userInfo"));

      this.menu.map((i, idx) => {
        if (i.pageName == to.name) {
          this.current = idx;
        }
      });
    },
  },
  created() {},
  mounted() {
    this.menuHandle();
    this.info = JSON.parse(localStorage.getItem("userInfo"));
  },
  methods: {
    menuHandle(e, idx) {
      console.log(this.$store.state.openlive);
      if (this.$store.state.openlive) {
        this.$message.error("请先关闭直播");
        return;
      }
      this.current = e ? idx : 0;
      const linkInfo = this.menu[this.current];
      console.log("[home] load page:", linkInfo);
      this.$router.push({ name: linkInfo.pageName, params: linkInfo.params });
    },
    toUser() {
      this.$router.push({ name: "setting" });
    },
    handleClose() {
      this.$ipc.invoke(ipcApiRoute.closeApp).then((r) => {});
    },
    handleMinus() {
      this.$ipc.invoke(ipcApiRoute.handleApp, "mini").then((r) => {});
    },
    handleClick(command) {
      console.log(command);
      switch (command) {
        case "user":
          console.log(11);
          this.$router.push({ name: "setting" });
          break;
        case "logout":
          localStorage.clear();
          this.$router.push({ name: "login" });
          break;
        case "exit":
          this.$ipc.invoke(ipcApiRoute.closeApp).then((r) => {});
          break;
        default:
          this.$router.push({ name: "edit" });
          break;
      }
    },
  },
};
</script>
<style lang="less" scoped>
// 嵌套
#app-layout-sider {
  height: 100%;
  background: #f8f8f8;
  .pic-logo {
    width: 68px;
    height: 57px;
    margin: 10px;
  }
  .layout-sider {
    max-width: 90px !important;
    min-width: 90px !important;
  }
  .menubox {
    .item:first-child {
      margin-top: 20px;
    }
    .item {
      margin: 30px auto;
      font-size: 15px;
      color: #939dae;
      width: 65px;
    }

    .active {
      background: #fff;
      color: #2e74ff;
      border-radius: 6px;
      padding: 8px 2px;
    }

    img {
      width: 34px;
      height: 30px;
    }
  }
  .menu-item {
    .ant-menu-item {
      background-color: #fff;
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 0 0px !important;
    }
  }
  .layout-content {
    background-color: #fff;

    .nav {
      -webkit-app-region: drag;
      display: flex;
      justify-content: space-between;
      font-size: 19px;
      text-align: left;
      font-weight: bold;
      color: #000;
      padding: 15px 26px 6px;
      box-shadow: 0px 8px 21px rgba(34, 34, 34, 0.05);
      margin-bottom: 1px;

      .infoBox {
        display: flex;
        align-items: center;
        border-radius: 6px;
        padding: 8px 25px;
      }
    }
  }

  .contrl {
    display: flex;
    align-items: center;
    border-radius: 6px;
    background: rgb(247, 247, 247);
    padding: 8px 20px;
    width: 100px;
    justify-content: space-between;
    text-align: center;
    div {
      width: 30px;
      height: 30px;
    }
  }
}

::v-deep {
  .ant-menu-item-selected,
  .ant-menu-item:hover {
    color: #409eff;
  }
  .ant-layout-sider-children {
    background: #f1f5f9;
  }
  .ant-layout {
    overflow: hidden !important;
  }
}
</style>
